export const experienceData = [
    {
        id: 1,
        company: 'FREELANCING',
        jobtitle: 'Senior Blockchain Developer',
        startYear: 'Apr 2024',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'INFINYTY FORCE',
        jobtitle: 'FullStack Blockchain Developer',
        startYear: 'Dec 2022',
        endYear: 'Mar 2024'
    },
    {
        id: 3,
        company: 'BIDIFY',
        jobtitle: 'Smart Contract Developer',
        startYear: 'Dec 2019',
        endYear: 'Nov 2022'
    },
    {
        id: 4,
        company: 'WIZARDSDEV',
        jobtitle: 'Web Developer',
        startYear: 'Oct 2017',
        endYear: 'Dec 2019'
    },
]