export const achievementData = {
    bio: "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
    achievements: [
        {
            id: 1,
            title: 'Problem Solving (Intermediate) Certificate',
            details: 'It covers topics of Data Structures (such as HashMaps, Stacks and Queues) and Algorithms (such as Optimal Solutions).',
            date: 'Jul 25, 2024',
            field: 'Problem Solving',
            image: 'certification1.png'
        },
        {
            id: 2,
            title: 'Problem Solving (Basic) Certificate',
            details: 'It covers basic topics of Data Structures (such as Arrays, Strings) and Algorithms (such as Sorting and Searching).',
            date: 'Jul 24, 2024',
            field: 'Problem Solving',
            image: 'certification2.png'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/