import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: ' Turbo Trades ',
        projectDesc: 'This a decentralized finance (DeFi) platform designed for easy and secure wallet connection, swapping, liquidity management, and trading on both Maya and Thorchain networks.',
        tags: ['Next', 'COSMOS SDK', 'BRIDGE'],
        code: 'https://github.com/VisionarySuperstar',
        demo: 'https://turbotrade.tech/',
        image: one
    },
    {
        id: 2,
        projectName: ' Vulcanpad ',
        projectDesc: 'This project is a platform that facilitates the launch and investment of innovative blockchain projects through secure, transparent, and efficient Initial Coin Offerings (ICOs) on the Arbitrum, Base, and BNB Networks.',
        tags: ['Moralis API', 'MultiChain', 'ICO'],
        code: 'https://github.com/VisionarySuperstar',
        demo: 'https://vulcanpad.tech/',
        image: three
    },
    {
        id: 3,
        projectName: 'MarsWTF',
        projectDesc: 'This is a meme token presale platform built on the Base Chain, aiming to be a gateway to the future of meme tokens on Mars.',
        tags: ['Solidity', 'Presale', 'MemeToken'],
        code: 'https://github.com/VisionarySuperstar',
        demo: 'https://mars-wtf-0-0-0.vercel.app/',
        image: two
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/